// material-ui
import { Stack, Typography } from '@mui/material'

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
	<Stack
		direction='row'
		justifyContent='space-between'
		alignItems='center'
		sx={{ p: '24px 16px 0px', mt: 'auto' }}
	>
		<Typography variant='caption'>&copy; ToonTube 2025</Typography>
	</Stack>
)

export default Footer
